import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { BUTTON_TYPE } from '../../../../constants/globals';
import './button.css';

/**
 *
 * @param {object} props
 * @param {string} props.type - type of button (primary or secondary)
 * @param {string} props.className
 * @param {string} props.children
 * @returns JSX.Element
 */
function Button({ children, type = 'primary', className = '', ...rest }) {
    return (
        <button {...rest}
            className={clsx('btn', BUTTON_TYPE[type], className)}
            type="submit"
        >
            {children}
        </button>
    );
}

export default Button;

Button.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary']),
    className: PropTypes.string,
};
