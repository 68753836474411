import React from 'react';
import HeroBG from '../assets/images/about-hero.png';
import HeroBGMobile from '../assets/images/about-hero-mobile.png';
import NetworkBG from '../assets/images/network-bg.svg';
// import TeamMember1 from '../assets/images/team-member-1.png';
// import TeamMember2 from '../assets/images/team-member-2.png';
import useWindowWidth from '../hooks/useWindowWIdth';
import MetaTags from '../components/common/MetaTags';

function About() {
    const isMobile = useWindowWidth() < 768;

    return (
        <div data-aos="zoom-out" data-aos-duration="500">
            <MetaTags page="about" />
            {/* hero section */}
            <section
                className="w-full h-[330px] sm:h-[450px] md:h-[500px] 1xl:h-[800px] bg-no-repeat bg-cover bg-top-30% md:bg:top md:bg-bottom p-[20px]"
                style={{
                    backgroundImage: `url(${isMobile ? HeroBGMobile : HeroBG})`,
                }}
            >
                <div className="w-[85%] lg:w-3/4 px-0 lg:px-[60px] pt-[80px] sm:pt-[120px] md:pt-[180px] 1xl:pt-[300px] text-white">
                    <h2 data-aos="fade-left" className="text-58px md:text-68px font-semibold uppercase mb-[20px]">
                        About ir solutions
                    </h2>
                    <p data-aos="fade-up" data-aos-delay="300" className="w-full lg:w-3/4 text-30px">
                        IR Solutions is a blockchain development company working
                        on web 3.0 technologies to create solutions for real
                        world problems through blockchain.
                    </p>
                </div>
            </section>
            <section
                className="w-full h-[430px] sm:h-[500px] md:h-[750px] lg:h-[400px] xl:h-[500px] 2xl:h-[650px] bg-no-repeat bg-75% lg:bg-contain bg-bottom-right lg:bg-right p-[20px] sm:mt-[30px] text-center lg:text-left mb-10"
                style={{ backgroundImage: `url(${NetworkBG})` }}
            >
                <div className="lg:w-3/4 lg:px-[60px] sm:pt-[20px]">
                    <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold uppercase mb-[20px]">
                        broad <span className="text-primary">network</span>
                    </h2>
                    <p data-aos="fade-up" data-aos-delay="300" className="lg:w-[50vw] xl:w-[70vw] font-semibold mb-3 text-30px">
                        Being in the blockchain space from years, IR Solutions
                        has a wide network of blockchain experts, advisors,
                        partners helping startups and companies to contribute
                        into the web 3.0 ecosystem.
                    </p>
                    <p data-aos="fade-up" data-aos-delay="500" className="lg:w-[45vw] 1xl:w-3/4 font-semibold text-30px">
                        Our network covers token launch, smart contracts,
                        audits, NFT marketplace, wallet development, and
                        everything you may need to fulfill your vision and goal
                        to create productive blockchain project in the web 3.0
                        space.
                    </p>
                </div>
            </section>

            {/* <hr className="opacity-10 border-b-2 my-[30px] lg:my-[60px]" /> */}

            {/* team section */}

            {/* <section className="text-center mb-[85px]">
                <h2 className="text-58px md:text-68px font-bold uppercase mb-[20px]">
                    Team
                </h2>
                <p className="font-semibold w-3/4 mx-auto text-30px">
                    Meet the team of IR Solutions working on a mission to bring
                    people more opportunities and solve real world problems
                    through blockchain.
                </p>

                <div className="flex justify-center flex-col md:flex-row gap-x-20 mt-[90px]">
                    <div className="mb-[38px] md:mb-0">
                        <img width={250} src={TeamMember1} alt="" />
                        <p className="text-50px md:text-38px font-semibold mt-[30px] md:mt-[60px] mb-2">
                            Rao Naveed
                        </p>
                        <h3 className="font-medium text-gray text-30px md:text-24px">
                            CEO | Founder
                        </h3>
                    </div>
                    <div>
                        <img width={250} src={TeamMember2} alt="" />
                        <p className="text-50px md:text-38px font-semibold mt-[30px] md:mt-[60px] mb-2">
                            Muhammad Ahsan
                        </p>
                        <h3 className="font-medium text-gray text-30px md:text-24px">
                            Co-Founder
                        </h3>
                    </div>
                </div>
            </section> */}
        </div>
    );
}

export default About;
