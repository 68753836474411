import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../assets/images/logo.svg';
import { CAREER_PAGE_URL, NAV_LINKS } from '../constants/globals';

// scroll observer, this below code adding class to header when scroll down.
// It is outside of the component because we don't want to re-render the component
window.addEventListener('scroll', () => {
    const header = document.querySelector('header');
    header.classList.toggle('nav-scroll-active', window.scrollY > 0);
});

function Header() {
    return (
        <div>
            <header className="px-[16px] sm:px-[24px] md:px-[40px] lg:px-[60px] py-3 flex items-center justify-between fixed top-0 z-10 w-full">
                <Link to="/">
                    <img
                        className="w-[24px] sm:w-[40px] lg:w-[60px]"
                        alt="IR solutions"
                        src={Logo}
                    />
                </Link>

                <nav>
                    <ul className="flex items-center">
                        {NAV_LINKS.map(({ title, path, key, externalLink }) => (
                            <li
                                className="text-white mr-[12px] sm:mr-[24px] md:mr-[40px] last:mr-0"
                                key={key}
                            >
                                {externalLink ? (
                                    <a
                                        href={CAREER_PAGE_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Careers
                                    </a>
                                ) : (
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'text-primary font-semibold'
                                                : undefined
                                        }
                                        to={path}
                                    >
                                        {title}
                                    </NavLink>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </header>
        </div>
    );
}

export default Header;
