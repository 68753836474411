import React, { useEffect, useRef } from 'react';
import useWindowWidth from '../../hooks/useWindowWIdth';

function Video({
    desktopSource,
    mobileSource = null,
    controls = false,
    autoPlay = true,
    playOnHover = false,
    ...rest
}) {
    const isDesktop = useWindowWidth() > 768;

    const videoRef = useRef();

    useEffect(() => {
        if (mobileSource) {
            videoRef.current.load(); // it'll reload the source.
        }
    }, [isDesktop]);

    // it plays the video on hover
    const handleMouseEnter = () => {
        if (playOnHover) {
            videoRef.current.play();
        }
    };

    return (
        <div className="relative">
            <video
                onMouseEnter={handleMouseEnter}
                playsInline
                ref={videoRef}
                width="100%"
                controls={controls}
                autoPlay={autoPlay}
                muted
                loop
                {...rest}
            >
                <track kind="captions" />
                <source
                    src={isDesktop ? desktopSource : mobileSource}
                    type="video/mp4"
                    media="(min-width: 768px)"
                />
            </video>

            {controls && (
                <div className="w-full h-full absolute top-0 flex items-end justify-start p-5">
                    <button
                        type="button"
                        className="text-white w-[50px] h-[50px] text-16px font-semibold border border-white rounded-full"
                    >
                        Play
                    </button>
                </div>
            )}
        </div>
    );
}

export default Video;
