import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroBG from '../assets/images/contact-bg.png';
import HeroBGMobile from '../assets/images/contact-bg-mobile.png';
import { ReactComponent as MailIcon } from '../assets/icons/mail-icon.svg';
import IslamabadSVG from '../assets/icons/islamabad.svg';
import TurkeySVG from '../assets/icons/turkey.svg';
import ContactForm from '../components/home/ContactForm';
import useWindowWidth from '../hooks/useWindowWIdth';
import MetaTags from '../components/common/MetaTags';

function Contact() {
    const LOCATIONS = [
        {
            title: 'Islamabad',
            address:
                'Office 10, 3rd Floor, Al Rehmat Plaza, G11 Markaz, Islamabad, Pakistan',
            icon: IslamabadSVG,
            iframe: {
                title: 'Islamabad Office location',
                src: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13282.147603549716!2d73.0000786!3d33.6691594!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x19cb65761b9541fd!2sIR%20solutions!5e0!3m2!1sen!2s!4v1664955982830!5m2!1sen!2s',
            },
            key: 'islamabad',
        },
        {
            type: 'divider',
            key: 'divider',
        },
        {
            title: 'Türkiye',
            address:
                'Cumhuriyet, İncirli Dede Cd. floor 41 Şişli/İstanbul, Turkey',
            icon: TurkeySVG,
            iframe: {
                title: 'Turkey Office location',
                src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.5831727461464!2d28.974277916213452!3d41.05624357929597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab71630edf0cf%3A0x36e758c5970b2b80!2zQ3VtaHVyaXlldCwgxLBuY2lybGkgRGVkZSBDZC4sIMWeacWfbGkvxLBzdGFuYnVsLCBUdXJrZXk!5e0!3m2!1sen!2s!4v1664956788893!5m2!1sen!2s',
            },
            key: 'turkey',
        },
    ];

    const isMobile = useWindowWidth() < 768;

    return (
        <div data-aos="zoom-out" data-aos-duration="500">
            <Helmet>
                <title>Contact IR SOLUTIONS</title>
                <meta
                    name="description"
                    content="We're one just one mail away to assist all of your queries and also to help you in anything you need to excel your idea and vision for creating a better blockchain product. hello@irsolutions.tech"
                />
                <link rel="canonical" href="/contact" />
            </Helmet>
            <MetaTags page="contact" />
            <section
                className="w-full h-[480px] sm:h-[600px] md:h-[400px] lg:h-[600px] 1xl:h-[800px] bg-no-repeat bg-cover bg-bottom-right lg:bg-right p-[20px]"
                style={{
                    backgroundImage: `url(${isMobile ? HeroBGMobile : HeroBG})`,
                }}
            >
                <div className="w-full md:w-3/4 px-[10px] md:px-[24px] lg:px-[40px] pt-[68px] md:pt-[100px] xl:pt-[180px] 1xl:pt-[250px] text-white">
                    <h1 data-aos="fade-left" className="text-58px md:text-68px font-bold uppercase mb-[20px]">
                        CONNECT WITH <span className="text-primary">US</span>
                    </h1>
                    <p data-aos="fade-up" data-aos-delay="300" className="w-[80vw] md:w-[65%] 2xl:w-3/4 mb-0 lg:mb-[26px] text-30px">
                        We&apos;re one just one mail away to assist all of your
                        queries and also to help you in anything you need to
                        excel your idea and vision for creating a better
                        blockchain product.
                    </p>
                    <a data-aos="fade-left" className="text-30px" href="mailto:hello@irsolutions.tech">
                        <MailIcon className="mr-3 w-[30px] lg:w-[50px] svg-fill-white" />
                        <span className="align-middle">
                            hello@irsolutions.tech
                        </span>
                    </a>
                </div>
            </section>

            <section className="py-[60px] text-center">
                <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold uppercase mb-[50px] 1xl:mb-[100px]">
                    OUR LOCATION
                </h2>

                <div data-aos="fade-up" className="flex justify-center flex-col lg:flex-row gap-y-20 lg:gap-y-0 lg:mx-4">
                    {LOCATIONS.map(
                        ({
                            title,
                            key,
                            address,
                            icon,
                            iframe,
                            type = 'content',
                        }) =>
                            type === 'divider' ? (
                                <div
                                    key={key}
                                    className="border-2 border-[#00000010]"
                                />
                            ) : (
                                <div className="w-full" key={key}>
                                    <div className="h-[140px] flex items-end justify-center">
                                        <img
                                            width={138}
                                            src={icon}
                                            alt="capital city of pakistan, islamabad"
                                        />
                                    </div>
                                    <h2 className="text-50px uppercase font-black mt-[30px]">
                                        {title}
                                    </h2>
                                    <address className="not-italic min-h-[72px] text-30px w-[85%] xl:w-3/4 mx-auto my-[30px] font-medium">
                                        {address}
                                    </address>

                                    <div className="w-[80vw] md:w-[400px] h-[240px] 1xl:w-[500px] 1xl:h-[340px] mx-auto">
                                        <iframe
                                            src={iframe.src}
                                            width="100%"
                                            height="100%"
                                            title={iframe.title}
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        />
                                    </div>
                                </div>
                            ),
                    )}
                </div>
            </section>

            {/* get in touch - contact form */}
            <section className="min-h-[max-content] bg-primary-700 py-[55px] text-white border-b border-white">
                <div className="px-[24px] sm:px-[50px]">
                    <h2 className="text-58px font-semibold uppercase leading-10 mb-[40px]">
                        Get in touch
                    </h2>

                    <ContactForm />
                </div>
            </section>
        </div>
    );
}

export default Contact;
