export const BUTTON_TYPE = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
};

export const NAV_LINKS = [
    {
        title: 'MetaTor',
        path: '/explore-metator',
        key: 'explore-metator',
    },
    {
        title: 'Web 3.0',
        path: '/web3',
        key: 'web3',
    },
    {
        title: 'About',
        path: '/about',
        key: 'about',
    },
    {
        title: 'Careers',
        path: '/careers',
        key: 'careers',
        externalLink: true,
    },
    {
        title: 'Contact',
        path: '/contact',
        key: 'contact',
    },
];

export const CAREER_PAGE_URL = 'https://careers.irsolutions.tech/';
