import React from 'react';
import WebIcon from '../../assets/icons/web-icon.svg';
import SmartContractsIcon from '../../assets/icons/smart-contracts.svg';
import MetaIcon from '../../assets/icons/meta-icon.svg';
import BlockchainIcon from '../../assets/icons/blockchain-icon.svg';
import AppIcon from '../../assets/icons/app-icon.svg';
import AIMachineIcon from '../../assets/icons/ai-machine-icon.svg';

function Services() {
    const WHAT_WE_DO = [
        {
            title: 'Web Development',
            description:
                'We build websites that are fast, responsive, and secure. We use the latest technologies to build websites that are easy to use and maintain.',
            icon: WebIcon,
            key: '1',
        },
        {
            type: 'divider',
            key: '2',
        },
        {
            title: 'AI / MACHINE LEARNING',
            description:
                'Senior graphic designers, video artists, UX/UI Designers having expertise in Logo & Identity Designing, Marketing & Advertisement Designing , Art & Illustrations, etc.',
            icon: AIMachineIcon,
            key: '3',
        },
        {
            title: 'BLOCKCHAIN',
            description:
                'Expert Blockchain consultancy, development services, implementing key blockchain strategies, experts in creating Smart contracts, e-wallets, enterprising grade blockchain and etc.',
            icon: BlockchainIcon,
            key: '4',
        },
        {
            type: 'divider',
            key: '5',
        },
        {
            title: 'METAVERSE / WEB 3.0',
            description:
                'Blockchain & technology experts, NFT project handling experts, Proficiency in solution and plan to build an interactive and versatile metaverse project in Virtual and augmented reality.',
            icon: MetaIcon,
            key: '6',
        },
        {
            title: 'APP DEVELOPMENT',
            description:
                'Business & Technical Analysis, Smart Contracts/Wallets developers, Backend/Frontend Developers, Internal Testing/Audit, Testnet Deplorers, Mainnet Deployer and etc.',
            icon: AppIcon,
            key: '7',
        },
        {
            type: 'divider',
            key: '8',
        },
        {
            title: 'SMART CONTRACTS',
            description:
                'Game development services in mobile & PC games, Blockchain & NFT game developers, Analyser of the Metaverse market, & the VR/AR Game developers.',
            icon: SmartContractsIcon,
            key: '9',
        },
    ];

    return (
        <div className="grid grid-cols-12 lg:grid-cols-11 gap-[16px] lg:gap-0">
            {WHAT_WE_DO.map(({ title, key, description, type, icon }, i) =>
                type === 'divider' ? (
                    <div
                        key={key}
                        className="hidden lg:block col-span-1 text-center opacity-30"
                    >
                        <span className="inline-block h-full border-r-2 border-white" />
                    </div>
                ) : (
                    <div
                        data-aos-delay={100 * i} data-aos="fade-down"
                        key={key}
                        className="flex items-center flex-col md:flex-row col-span-6 lg:col-span-5 mb-[40px] lg:mb-[100px] text-center sm:text-start"
                    >
                        <img
                            className="w-[120px] sm:w-[150px] xl:w-[194px]"
                            src={icon}
                            alt="web development"
                        />
                        <div>
                            <h3 className="text-30px font-semibold uppercase mb-[8px]">
                                {title}
                            </h3>
                            <p className="hidden md:block text-18px">
                                {description}
                            </p>
                        </div>
                    </div>
                ),
            )}
        </div>
    );
}

export default Services;
