import React from 'react';
import useWindowWidth from '../../hooks/useWindowWIdth';
import InfinityBG from '../../assets/images/infinity-bg.png';
import InfinityBGMobile from '../../assets/images/infinity-bg-mobile.png';
import InfinityGIF from '../../assets/images/infinity-gif.gif';

function InfinityBeyond() {

    const isMobile = useWindowWidth() < 768;


    return (
        <section
            className="w-full min-h-[60vh] sm:min-h-[60vh] lg:min-h-[600px] xl:min-h-[650px] 1xl:min-h-[80vh] 2xl:min-h-[100vh] bg-no-repeat bg-cover p-[20px] py-[10px] 2xl:py-[50px] mt-4"
            style={{
                backgroundImage: `url(${isMobile ? InfinityBGMobile : InfinityBG
                    })`,
            }}
        >
            <div className="mx-auto w-[80vw] md:w-3/4 text-center flex flex-col-reverse md:flex-col items-center">
                <img
                    className="w-[450px] xl:w-[600px] 1xl:w-[800px] 2xl:w-[960px] inline-block"
                    src={InfinityGIF}
                    alt=""
                />
                <div className="mt-20 md:mt-0">
                    <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold uppercase mb-[20px]">
                        <span className="text-primary">INFINITY</span> AND
                        BEYOND
                    </h2>
                    <p data-aos-delay="300" data-aos="fade" className="font-semibold w-3/4 mx-auto text-30px">
                        Being in the era of web 3.0 and blockchain, we
                        believe that opportunities are infinite when created
                        with passion and ideas to contribute more for the
                        people in the web3.0 ecosystem
                    </p>
                </div>
            </div>
        </section>

    );
}

export default InfinityBeyond;