import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../common/Form';

function ContactForm() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    // TODO : remove the log message in prod.
    const onSubmit = (data) => {
        // console.log(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-5">
                <div className="grid grid-cols-2 flex-1 gap-5 col-span-2 lg:col-span-1">
                    <div>
                        <input
                            className="primary-input"
                            defaultValue=""
                            placeholder="Name"
                            {...register('name', { required: true })}
                        />
                        {errors.name && (
                            <span className="text-14px">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div>
                        <input
                            className="primary-input"
                            placeholder="Last Name"
                            defaultValue=""
                            {...register('lastName', { required: true })}
                        />
                        {errors.lastName && (
                            <span className="text-14px">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className="col-span-2">
                        <input
                            className="primary-input"
                            type="email"
                            placeholder="Email"
                            {...register('email', {
                                required: true,
                                pattern: /^\S+@\S+$/i,
                            })}
                        />
                        {errors.email && (
                            <span className="text-14px">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className="col-span-2">
                        <input
                            className="primary-input"
                            type="text"
                            placeholder="Subject"
                            {...register('subject', { required: true })}
                        />
                        {errors.subject && (
                            <span className="text-14px">
                                This field is required
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex-1 col-span-2 lg:col-span-1">
                    <textarea
                        className="primary-textarea"
                        type="text"
                        placeholder="Message"
                        {...register('message', { required: true })}
                    />
                    {errors.message && (
                        <span className="text-14px">
                            This field is required
                        </span>
                    )}
                </div>
            </div>

            <div className="flex justify-end mt-[20px]">
                <Button type="secondary" className="!h-[55px]">
                    Send
                </Button>
            </div>
        </form>
    );
}

export default ContactForm;
