import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './input.css';

/**
 *
 * @param {object} props
 * @param {string} props.type - type of text input (text, email or password)
 * @param {string} props.className - css classNames to be added to the input
 * @param {string | number} props.width - width of input, by default it is 100%
 * @param {func} props.onChange - onchange event handler
 * @returns JSX.Element
 */
function Input({
    label = '',
    name = '',
    type = 'text',
    className = '',
    width = '100%',
    onChange = () => {},
    value,
    ...rest
}) {
    return (
        <label htmlFor="input">
            {label}
            <input
                id="input"
                name={name}
                type={type}
                style={{ width }}
                className={clsx('primary-input', className)}
                onChange={onChange}
                value={value || ''}
            />
        </label>
    );
}

function TextArea() {
    return <textarea />;
}

Input.TextArea = TextArea;
export default Input;

Input.propTypes = {
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'email', 'password']),
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
};
