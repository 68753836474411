import React from 'react';
import {
    LazyLoadImage,
    LazyLoadComponent,
} from 'react-lazy-load-image-component';
import HeroVideo from '../assets/videos/web3-promo.mp4';
import HeroVideoMobile from '../assets/videos/web3-promo-mobile.mp4';
import NFTSVG from '../assets/images/web3-nft.png';
import BlockChainSVG from '../assets/images/web3-blockchain.png';
import MetaverseSVG from '../assets/images/web3-metaverse.png';
import CryptoWorld from '../assets/images/crypto-world.png';
import Video from '../components/common/Video';
import MetaTags from '../components/common/MetaTags';

function Web3() {
    const WEB3_TECHNOLOGIES = [
        {
            title: 'NFT',
            description:
                'Bring your NFTs and their use cases to bring value and quality product for the community. Team IR Solutions knows what it takes to launch a successful NFT project covering all the technical and marketing aspects in the market.',
            image: NFTSVG,
            key: 'nft',
        },
        {
            title: 'Blockchain',
            description:
                'Future belongs to blockchain as we are moving towards the era of decentralization and web 3.0. IR Solutions provides all technical expertise you need to develop your own product powered by blockchain.',
            image: BlockChainSVG,
            key: 'blockchain',
        },
        {
            title: 'Metaverse',
            description:
                'Team IR Solutions got you covered in launching your own Metaverse app supporting all VR devices to bring experiences and new world to people to live smart life.',
            image: MetaverseSVG,
            key: 'metaverse',
        },
    ];

    return (
        <div data-aos="zoom-out" data-aos-duration="500">
            <MetaTags page="web3" />
            {/* hero section */}
            <section>
                <Video
                    mobileSource={HeroVideoMobile}
                    desktopSource={HeroVideo}
                />
            </section>
            <section className="text-center py-[50px] px-[20px] lg:px-[40px] xl:px-[50px] 1xl:px-[80px]">
                <h1 data-aos="fade-left" className="text-58px md:text-68px font-bold uppercase">
                    We are dealing with new <br className="hidden lg:block" />
                    <span className="text-primary">WEB 3.0</span> technology
                </h1>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 xl:gap-5">
                    {WEB3_TECHNOLOGIES.map(
                        ({ title, description, image, key }) => (
                            <div
                                className="w-[70vw] mx-auto lg:mx-0 lg:w-full"
                                key={key}
                                data-aos="slide-up"
                            >
                                <LazyLoadImage
                                    alt={title}
                                    effect="blur"
                                    src={image}
                                />
                                <h2 className="text-58px font-bold uppercase text-primary mb-[30px]">
                                    {title}
                                </h2>
                                <p className="text-24px font-semibold">
                                    {description}
                                </p>
                            </div>
                        ),
                    )}
                </div>
            </section>

            <LazyLoadComponent>
                <section
                    className="w-full h-[750px] 2xl:h-[100vh] bg-no-repeat bg-cover p-[30px] md:p-[50px] text-white mt-[60px] 1xl:mt-[90px] bg-center"
                    style={{ backgroundImage: `url(${CryptoWorld})` }}
                >
                    <div className="mx-auto w-full md:w-3/4 text-center">
                        <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold uppercase mb-[14px]">
                            CRYPTO WORLD
                        </h2>
                        <p data-aos="fade" className="font-semibold w-full xl:w-3/4 mx-auto">
                            Embrace the blockchain and web 3.0 to create
                            businesses and jobs opportunities in this Crypto
                            world.
                        </p>
                    </div>
                </section>
            </LazyLoadComponent>
        </div>
    );
}

export default Web3;
