import React from 'react';
import { Link } from 'react-router-dom';
import HeroVideo from '../assets/videos/home-hero.mp4';
import HeroVideoMobile from '../assets/videos/home-hero-mobile.mp4';
import IntroducingMetaTor from '../assets/videos/meta-web.mp4';
import IntroducingMetaTorMobile from '../assets/videos/meta-mobile.mp4';
import ContactForm from '../components/home/ContactForm';
import Video from '../components/common/Video';
import MetaTags from '../components/common/MetaTags';
import BringIdeas from '../components/home/BringIdeas';
import WhatWeDo from '../components/home/WhatWeDo';
import Blockchain from '../components/home/Blockchain';
import InfinityBeyond from '../components/home/InfinityBeyond';
import CryptoChains from '../components/home/CryptoChains';
import Innovation from '../components/home/Innovation';
import { Button } from "../components/common/Form/index";

// import HomeHeroPoster from '../assets/images/home-hero-poster.png';

function Home() {
    return (
        <div>
            <MetaTags page="home" />

            {/* hero section */}
            <section>
                <Video
                    mobileSource={HeroVideoMobile}
                    desktopSource={HeroVideo}
                />
            </section>

            <BringIdeas />

            {/* services - section */}

            <WhatWeDo />

            {/* introducing metaTor section */}
            <section data-aos="fade" className='relative'>
                <Video
                    mobileSource={IntroducingMetaTorMobile}
                    desktopSource={IntroducingMetaTor}
                    autoPlay={false}
                    playOnHover
                />
                <div className="absolute bottom-[34%] sm:bottom-[36%]  md:bottom-[20%] lg:bottom-[22%] xl:bottom-[23%] 1xl:bottom-[25%] left-1/2 -translate-x-1/2 md:-translate-y-0">
                    <a href="https://metator.com/" target="_blank" rel="noreferrer">
                        <Button className="mt-5 !h-[45px] md:!h-[50px] lg:!h-[55px] !min-w-[160px] md:!min-w-[200px]" type="secondary">Explore</Button>
                    </a>
                </div>

            </section>

            {/* IR work in blockchain technology */}
            <Blockchain />

            {/* infinity section */}
            <InfinityBeyond />

            {/* crypto chains section */}
            <CryptoChains />

            {/* crypto chains section */}
            <Innovation />

            {/* get in touch - contact form */}
            <section className="min-h-[max-content] bg-primary-700 py-[30px] lg:py-[55px] text-white border-b border-white">
                <div className="px-[24px] md:px-[40px] lg:px-[50px]">
                    <h2 className="text-58px font-semibold uppercase leading-10 mb-[40px]">
                        Get in touch
                    </h2>
                    <ContactForm />
                </div>
            </section>
        </div>
    );
}

export default Home;
