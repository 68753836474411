import React from 'react';
import Video from '../components/common/Video';
import MetaTags from '../components/common/MetaTags';
import IntroducingMetaTor from '../assets/videos/meta-web.mp4';
import IntroducingMetaTorMobile from '../assets/videos/meta-mobile.mp4';
import ExperienceNewWorldBG from '../assets/images/experience-new-world.png';
import ExperienceNewWorldMobile from '../assets/images/experience-new-world-mobile.png';
import CommunicationBG from '../assets/images/communication.png';
import CommunicationBGMobile from '../assets/images/communication-mobile.png';
import UniqueExperiences from '../assets/images/unique-experiences.png';
import UniqueExperiencesMobile from '../assets/images/unique-experiences-mobile.png';
import Experiences from '../assets/images/experiences.png';
import VRGlasses from "../assets/images/vr-glasses.svg";
import useWindowWidth from '../hooks/useWindowWIdth';
import { Button } from "../components/common/Form/index";

function ExploreMetator() {

    const isMobile = useWindowWidth() < 992;

    return (
        <div data-aos="zoom-out" data-aos-duration="500">
            <MetaTags page="metator" />
            {/* hero section */}
            <section className='relative'>
                <Video
                    mobileSource={IntroducingMetaTorMobile}
                    desktopSource={IntroducingMetaTor}
                />

                <div className="absolute bottom-[34%] sm:bottom-[36%]  md:bottom-[20%] lg:bottom-[22%] xl:bottom-[23%] 1xl:bottom-[25%] left-1/2 -translate-x-1/2 md:-translate-y-0">
                    <a href="https://metator.com/" target="_blank" rel="noreferrer">
                        <Button className="mt-5  !h-[45px] md:!h-[50px] lg:!h-[55px] !min-w-[160px] md:!min-w-[220px]" type="secondary">Explore</Button>
                    </a>
                </div>
            </section>

            <section
                className="relative w-full"
            >
                <img width="100%" src={isMobile ? ExperienceNewWorldMobile : ExperienceNewWorldBG} alt="" />
                <div className="text-white absolute top-0 py-[20px] sm:py-[30px] md:py-[40px] px-[24px] md:px-[60px] xl:p-[60px] 1xl:p-[75px]">
                    <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold leading-10 mb-[20px] md:mb-[40px] capitalize">
                        EXPERIENCE METATOR WORLD
                    </h2>
                    <p data-aos-delay="300" data-aos="fade" className="w-[90%] sm:w-[50%] lg:w-1/2 font-semibold text-30px mb-[20px] sm:mb-[80px] lg:mb-[50px]">
                        We&apos;re entering into the world of Metaverse where you own the world to live your life with freedom for your better future. In MetaTor, you get to experience possibilities beyond reality that define your mission and make your vision come true. Team MetaTor os working on to provide you the power to make you run  your world and business on your own, where you don&apos;t  face the irregularities or hurdles to achieve your goals.
                    </p>
                    <img className='w-[40px] sm:w-[70px] md:w-[90px] lg:w-[120px] 1xl:w-[200px]' src={VRGlasses} alt="vr glasses" />
                </div>
            </section>

            <section
                className="relative w-full"
            >
                <img width="100%" src={isMobile ? CommunicationBGMobile : CommunicationBG} alt="" />
                <div className="text-white absolute top-0 py-[20px] md:py-[40px] px-[24px] md:px-[60px] xl:p-[60px] 1xl:p-[75px]">
                    <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold leading-10 mb-[20px] md:mb-[40px] capitalize">
                        NEW WAY OF COMMUNICATION
                    </h2>
                    <p data-aos-delay="300" data-aos="fade" className="w-[75%] sm:w-[70%] font-semibold text-30px">
                        Breaking barriers of limitations to let people get closer and connect interactively for a productive workflow. With Mixed Reality in MetaTor, you get to communicate with office colleagues, friends, and everyone in a whole new way of engagement.
                    </p>
                </div>
            </section>

            <section
                className="relative w-full"
            >
                <img width="100%" src={isMobile ? UniqueExperiencesMobile : UniqueExperiences} alt="" />
                <div className="text-white absolute top-0 py-[20px] md:py-[40px] px-[16px] md:px-[60px] xl:p-[60px] 1xl:p-[75px] text-center w-full">
                    <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold leading-10 mb-[16px] md:mb-[30px]">
                        METATOR UNIQUE EXPERIENCE
                    </h2>
                    <p data-aos-delay="200" data-aos="fade" className='mb-[20px] md:mb-[40px]'>Presenting immersive experiences in MetaTor to revolutionize the way of live for a better future.</p>
                    <img data-aos-delay="300" data-aos="fade-up" src={Experiences} alt="experiences" className='w-[300px] md:w-[450px] lg:w-[600px] xl:w-[800px] mx-auto' />
                </div>
            </section>

        </div>
    );
}

export default ExploreMetator;
