import React from 'react';

import useWindowWidth from '../../hooks/useWindowWIdth';
import CryptoChainsBG from '../../assets/images/crypto-chains.png';
import CryptoChainsBGMobile from '../../assets/images/crypto-chains-mobile.png';
import { Button } from "../common/Form";


function CryptoChains() {

    const isMobile = useWindowWidth() < 768;

    return (
        <section
            className="w-full md:h-[520px] lg:h-[640px] xl:h-[750px] 1xl:h-[900px] 2xl:h-[1080px] bg-no-repeat bg-contain bg-bottom-right md:bg-right py-[20px] md:py-[40px] md:px-[60px] xl:p-[60px] 1xl:p-[75px]"
            style={{
                backgroundImage: `url(${isMobile ? "" : CryptoChainsBG})`,
            }}
        >
            <div className="w-full md:w-[340px] lg:w-[45%] p-[16px] md:p-0 text-center md:text-start">
                <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold leading-10 capitalize mb-[10px]">
                    CROSS CHAIN
                </h2>
                <h3 data-aos="fade-left" className="text-2xl font-bold mb-[16px] md:mb-[30px] text-primary">
                    MULTIPLE SOLUTIONS
                </h3>
                <p data-aos-delay="300" data-aos="fade" className="md:w-[90%] lg:w-full font-semibold text-30px">
                    Team IR Solutions has been working with multiple
                    blockchain organizations and startups over the years. We
                    believe in creating solutions to make products bring
                    more value in the real world community using web 3.0
                    technologies. We&apos;ve partnered with well-renowned
                    blockchain products over the years and our blockchain
                    advisors are welcoming everyone to come up with more
                    ideas to make them reality in Web 3.0 space.
                </p>
                <Button data-aos="slide-down" className="mt-5 hidden md:block">Read More</Button>
            </div>
            <img src={CryptoChainsBGMobile} alt="" className='block md:hidden' width="100%" />
        </section>

    );
}

export default CryptoChains;