import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Web3 from '../pages/Web3';
import About from '../pages/About';
import Contact from '../pages/Contact';
import useScrollToTop from '../hooks/useScrollToTop';
import ExploreMetator from '../pages/ExploreMetator';

// const Home = loadable(() => import('../pages/Home'))

function AppRouter() {
    const routes = [
        {
            path: '/',
            component: <Home />,
            key: 'home',
        },
        {
            path: '/web3',
            component: <Web3 />,
            key: 'web',
        },
        {
            path: '/about',
            component: <About />,
            key: 'about',
        },
        {
            path: '/contact',
            component: <Contact />,
            key: 'contact',
        },
        {
            path: '/explore-metator',
            component: <ExploreMetator />,
            key: 'explore-metator',
        },
    ];

    // This is a custom hook that will scroll to the top of the page when the route changes
    useScrollToTop();

    return (
        <Routes>
            {routes.map(({ path, component, key }) => (
                <Route key={key} path={path} element={component} />
            ))}
        </Routes>
    );
}

export default AppRouter;
