import React from 'react';
import Services from "./Services";
import WhatWeDoBG from '../../assets/images/what-we-do-section-bg.png';


function WhatWeDo() {
    return (<section
        className="w-full min-h-[800px] text-white bg-no-repeat bg-cover bg-center py-[40px] lg:py-[65px] 1xl:py-[100px] px-[16px] sm:px-[24px] lg:px-[40px]"
        style={{ backgroundImage: `url(${WhatWeDoBG})` }}
    >
        <div className="text-center mb-[60px] lg:mb-[100px]">
            <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold uppercase mb-[20px]">
                What we do
            </h2>
            <p data-aos="fade" data-aos-delay="300" className="font-medium text-30px">
                Having years of expertise and research in blockchain, we
                work on all web3.0 technologies and solutions to bring
                professional and reliable blockchain products.
            </p>
        </div>

        <Services />
    </section>);
}

export default WhatWeDo;