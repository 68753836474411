import React from 'react';
import useWindowWidth from '../../hooks/useWindowWIdth';
import BlockchainBG from '../../assets/images/blockchain-bg.png';
import BlockchainBGMobile from '../../assets/images/blockchain-bg-mobile.png';
import { Button } from "../common/Form";

function Blockchain() {

    const isMobile = useWindowWidth() < 768;

    return (
        <section
            className="w-full h-[550px] sm:h-[65vh] md:h-[640px] xl:h-[800px] 1xl:h-[900px] 2xl:h-[100vh] bg-no-repeat  bg-contain sm:bg-cover bg-right-bottom lg:bg-top md:bg-bottom py-[20px] md:py-[40px] px-[24px] md:px-[60px] xl:p-[60px] 1xl:p-[75px]"
            style={{
                backgroundImage: `url(${isMobile ? BlockchainBGMobile : BlockchainBG
                    })`,
            }}
        >
            <div className="w-[600px] xl:w-1/2">
                <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold leading-10 mb-[10px]">
                    BLOCKCHAIN
                </h2>
                <h3 data-aos="fade-left" className="text-primary text-2xl font-bold mb-[16px] md:mb-[30px]">
                    NFT
                </h3>
                <p data-aos-delay="300" data-aos="fade" className="w-[60vw] md:w-[90%] lg:w-full font-semibold text-30px">
                    Team IR Solutions has been working with multiple
                    blockchain organizations and startups over the years. We
                    believe in creating solutions to make products bring
                    more value in the real world community using web 3.0
                    technologies. We&apos;ve partnered with well-renowned
                    blockchain products over the years and our blockchain
                    advisors are welcoming everyone to come up with more
                    ideas to make them reality in Web 3.0 space.
                </p>
                <Button data-aos="slide-down" className="mt-5">Read More</Button>
            </div>
        </section>

    );
}

export default Blockchain;