import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import AOS from 'aos';
import './App.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'aos/dist/aos.css';
import ErrorBoundary from './components/common/ErrorBoundary';
import Footer from './layout/Footer';
import Header from './layout/Header';
import AppRouter from './routes/AppRouter';

function App() {
    // initialize aos
    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: true,
            delay: 200,
        });
    }, []);

    return (
        <ErrorBoundary>
            <HelmetProvider>
                <BrowserRouter>
                    <Header />

                    <main>
                        <AppRouter />
                    </main>

                    <Footer />
                </BrowserRouter>
            </HelmetProvider>
        </ErrorBoundary>
    );
}

export default App;
