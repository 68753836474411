import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from '../data/socialLinks.json';
// # assets
import Logo from '../assets/images/logo.svg';
import FBIcon from '../assets/icons/fb.svg';
import YTIcon from '../assets/icons/youtube.svg';
import InstagramIcon from '../assets/icons/instagram.svg';
import LnIcon from '../assets/icons/linkedin.svg';

function Footer() {
    return (
        <footer className="bg-primary-700 py-[24px] lg:py-[50px] pb-[20px] text-white text-center lg:text-left">
            <div className="px-0 lg:px-[50px] lg:grid lg:grid-cols-11 gap-x-20">
                <div className="pb-10 lg:pb-0 px-8 sm:px-20 lg:px-0 lg:col-span-4 border-b border-[#ffffff60] lg:border-0">
                    <img
                        className="w-[60px] xl:w-[75px] 2xl:w-[100px]"
                        width={100}
                        src={Logo}
                        alt=""
                    />
                    <p className="text-28px lg:text-20px mt-[26px]">
                        IR Solutions is a blockchain development company working
                        on web 3.0 technologies to create solutions for real
                        world problems through blockchain.
                    </p>
                </div>
                <div className="py-[16px] lg:py-0 col-span-2 text-24 lg:text-18px border-b border-[#ffffff60] lg:border-0">
                    <h3 className="w-max lg:w-full mx-auto border-b-2 border-[#ffffff80] pb-2 uppercase font-semibold lg:mt-3 text-30px lg:text-24px  mb-[24px]">
                        Contact
                    </h3>
                    <a href="tel:+92 51 2761585">
                        +92 51 2761585
                    </a>
                    <br />
                    <a
                        href="mailto:hello@irsolutions.tech"
                        className="inline-block mt-[12px] xl:mt-[20px] 2xl:mt-[24px] border-0 lg:border-b border-white pb-1"
                    >
                        hello@irsolutions.tech
                    </a>
                </div>
                <div className="py-[16px] lg:py-0 col-span-2 border-b border-[#ffffff60] lg:border-0">
                    <h3 className="w-max lg:w-full mx-auto border-b-2 border-[#ffffff80] pb-2 uppercase font-semibold mt-3 text-30px lg:text-24px">
                        Links
                    </h3>
                    <ul className="w-max mx-auto lg:mx-0 text-24 lg:text-18px grid grid-cols-2 lg:flex flex-col gap-[12px] mt-[20px]">
                        <li>
                            <Link to="/web3">Web3</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/irsolutions-tech/jobs"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Careers
                            </a>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="py-[16px] lg:py-0 col-span-3 border-b border-[#ffffff60] lg:border-0">
                    <h3 className="w-max lg:w-full mx-auto border-b-2 border-[#ffffff80] pb-2 uppercase font-semibold mt-3 text-30px lg:text-24px">
                        Follow us for more
                    </h3>
                    <div className="mx-auto lg:mx-0 w-max lg:w-auto grid grid-cols-2 lg:flex items-center justify-between mt-[24px] lg:mt-[50px]  gap-10 lg:gap-0">
                        <a
                            href={SocialLinks.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className="w-[24px] lg:w-[30px] xl:w-[38px] 1xl:w-[48px]"
                                src={FBIcon}
                                alt="Facebook URL"
                            />
                        </a>
                        <a
                            href={SocialLinks.youtube}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className="w-[28px] lg:w-[34px] xl:w-[44px] 1xl:w-[57px]"
                                src={YTIcon}
                                alt="Youtube URL"
                            />
                        </a>
                        <a
                            href={SocialLinks.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className="w-[24px] lg:w-[30px] xl:w-[38px] 1xl:w-[48px]"
                                src={InstagramIcon}
                                alt="Instagram URL"
                            />
                        </a>
                        <a
                            href={SocialLinks.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className="w-[20px] lg:w-[24px] xl:w-[34px] 1xl:w-[40px]"
                                src={LnIcon}
                                alt="Linkedin URL"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex justify-between flex-col lg:flex-row px-[50px] mt-5 lg:mt-0">
                <p className="text-30px lg:text-18px mb-5 lg:mb-0">
                    Copyright 2022. IR Solutions Group of Companies.
                </p>
                <p className="text-24 lg:text-18px">
                    Privacy policy | Terms of Use
                </p>
            </div>
        </footer>
    );
}

export default Footer;
