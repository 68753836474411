import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Meta from '../../data/meta.json';

/**
 * Returns the path for a favicon based on the given color scheme.
 *
 * @param {boolean} isDarkMode If currently in dark mode
 */
const getFaviconPath = (isDarkMode = false) =>
    `/favicon-${isDarkMode ? 'dark' : 'light'}.ico`;

/**
 *
 * @param {object} - props
 * @param {string} props.page - page key from meta.json
 * @returns
 */
function MetaTags({ page = 'home' }) {
    const [faviconHref, setFaviconHref] = useState('/favicon-light.ico');

    useEffect(() => {
        // Get current color scheme.
        const matcher = window.matchMedia('(prefers-color-scheme: dark)');
        // Set favicon initially.
        setFaviconHref(getFaviconPath(matcher.matches));
        // Change favicon if the color scheme changes.
        matcher.onchange = () =>
            setFaviconHref(getFaviconPath(matcher.matches));
    }, [faviconHref]);

    return (
        <Helmet>
            <link rel="icon" href={faviconHref} />
            <title>{Meta[page].title}</title>
            <meta property="og:title" content={Meta[page].title} />
            <meta name="description" content={Meta[page].description} />
            <meta property="og:description" content={Meta[page].description} />
            <link rel="canonical" href={Meta[page].link} />
            <meta property="og:url" content={Meta[page].link} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:title" content={Meta[page].title} />
            <meta name="twitter:description" content={Meta[page].description} />
        </Helmet>
    );
}

export default MetaTags;

MetaTags.prototype = {
    page: PropTypes.string.isRequired,
};
