import React from 'react';
import useWindowWidth from '../../hooks/useWindowWIdth';
import InnovationBG from '../../assets/images/innovation-bg.png';
import InnovationBGMobile from '../../assets/images/innovation-bg-mobile.png';
import InnovationIcons from '../../assets/images/innovation-icons.png';

function Innovation() {

    const isMobile = useWindowWidth() < 768;


    return (
        <section
            className="relative w-full h-max md:h-[640px] xl:h-[800px] 1xl:h-[900px] 2xl:h-[100vh] bg-no-repeat bg-contain md:bg-cover bg-top md:bg-bottom  py-0 md:py-[24px] lg:py-[40px] md:px-[60px] xl:p-[60px] 1xl:p-[75px]"
            style={{
                backgroundImage: `url(${isMobile ? "" : InnovationBG})`,
            }}
        >
            <img className='w-full block md:hidden' src={InnovationBGMobile} alt="" />
            <div className="text-white text-center md:text-start py-[30px] md:py-[20px] lg:py-[40px] absolute left-0 top-0 z-10 md:static w-full">
                <h2 data-aos="fade-left" className="text-58px md:text-68px font-bold leading-10 mb-[10px]">
                    EXTENDING YOUR REACH
                </h2>
                <h3 data-aos="fade-left" className="text-2xl font-bold mb-[30px] md:mb-[30px]">
                    WITH VR
                </h3>
                <p data-aos-delay="300" data-aos="fade" className="hidden md:block lg:w-[50vw] md:w-[55%] font-semibold text-30px mb-[60px] xl:mb-[100px]">
                    Technology is growing rapidly on a daily basis and IR Solutions team is committed to keep you updated. Virtual Reality has opened up the doors of new opportunities and experiences that let us achieve our goals with more freedom and ease. Entering into the world of mixed reality and metaverse, IR Solutions provides you with everything you need to create your own immersive virtual world to fuel your business and vision with unexplored opportunities.
                </p>
                <img className='w-[200px] lg:w-[300px] 1xl:w-[450px]' data-aos-delay="300" data-aos="fade-left" src={InnovationIcons} alt="" />
            </div>
        </section>

    );
}

export default Innovation;