import React from 'react';
import { Button } from "../common/Form";
import HumanAndRobotHands from '../../assets/images/human-robot-hand.png';

function BringIdeas() {
    return (
        <section
            className="w-full min-h-[432px] md:min-h-[526px] lg:min-h-[620px] xl:min-h-[685px] 2xl:min-h-[885px] bg-no-repeat bg-contain 1xl:bg-cover bg-bottom-90 lg:bg-center px-[30px] md:px-[50px] py-[40px] xl:py-[70px]"
            style={{ backgroundImage: `url(${HumanAndRobotHands})` }}
        >
            <div className="text-center lg:text-start">
                <h1 data-aos="fade-left" className="text-58px md:text-68px font-bold uppercase mb-[20px]">
                    We bring <span className="text-primary">IDEAS</span>{' '}
                    into life
                </h1>
                <p data-aos="fade" data-aos-delay="300" className="font-semibold w-full lg:w-1/2 text-30px">
                    IR Solutions is a blockchain development company backed
                    by blockchain experts and professionals solving real
                    world problems using web 3.0 technologies.
                </p>
                <Button className="mt-5" data-aos="slide-down">
                    Read More
                </Button>
            </div>
        </section>
    );
}

export default BringIdeas;